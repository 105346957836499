import { createAsyncThunk } from "@reduxjs/toolkit";
import { readPlaces } from "../../../api/places.api";

export const fetchPlaces = {
  search: createAsyncThunk("GET_ALL_PLACES", async (data, thunkAPI) => {
    let { query } = data;
    let payload = await readPlaces
      .search(query)
      .then((res) => {
        let { data } = res;
        let response = data.data && data.data;
        return response;
      })
      .catch((err) => {
        return err;
      });
    return payload;
  }),
  location: createAsyncThunk("GET_PLACES_BY_ID", async (data, thunkAPI) => {
    let { placeId } = data;
    let payload = await readPlaces
      .location(placeId)
      .then((res) => {
        let { data } = res;
        let response = data.data && data.data;
        return response;
      })
      .catch((err) => {
        return err;
      });
    return payload;
  }),
  fromLatLng: createAsyncThunk(
    "GET_PLACES_FROM_LAT_LNG",
    async (data, thunkAPI) => {
      let { lat_long } = data;
      let payload = await readPlaces
        .fromLatLng(lat_long)
        .then((res) => {
          let { data } = res;
          let response = data.data && data.data;
          return response;
        })
        .catch((err) => {
          return err;
        });
      return payload;
    }
  ),
};
