// -----------------------------------------------------------------
import instance from "./index";

// READ
export const readPlaces = {
  search: async (query) => await instance.get(`places/search?q=${query}`),
  location: async (placeId) => await instance.get(`places/${placeId}/location`),
  fromLatLng: async (lat_long) =>
    await instance.get(`places/geocode?lat_long=${lat_long}`),
};
