// -----------------------------------------------------------------
import instance from "./index";

// CREATE
export const createVehicle = {
  new: async (plate_number, color, brand, model, driver_id, type_id) =>
    await instance.post("vehicle_types", {
      plate_number,
      color,
      brand,
      model,
      driver_id,
      type_id,
    }),
  document: async (id, formData) =>
    await instance.post(`vehicles/${id}/documents`, formData),
};

// GET
export const readVehicle = {
  all: async () => await instance.get(`vehicle_types`),
  byId: async (id) => await instance.get(`vehicle_types/${id}`),
};
