import { createSlice } from "@reduxjs/toolkit";
import { fetchVehicles } from "../../actions/vehicle/vehicle.action";

const initialState = {
  allVehicles: {
    list: [],
    loading: false,
    error: null,
  },
  vehicleById: {
    list: [],
    loading: false,
    error: null,
  },
};
//
const vehiclesSlice = createSlice({
  name: "vehicles",
  initialState,
  extraReducers: {
    // allVehicles
    [fetchVehicles.all.pending]: (state) => {
      state.allVehicles.loading = true;
    },
    [fetchVehicles.all.fulfilled]: (state, action) => {
      state.allVehicles.loading = false;
      state.allVehicles.list = action.payload;
      state.allVehicles.error = null;
    },
    [fetchVehicles.all.rejected]: (state, action) => {
      state.allVehicles.loading = false;
      state.allVehicles.list = [];
      state.allVehicles.error = action.error.message;
    },
    //vehicleById
    [fetchVehicles.byId.pending]: (state) => {
      state.vehicleById.loading = true;
    },
    [fetchVehicles.byId.fulfilled]: (state, action) => {
      state.vehicleById.loading = false;
      state.vehicleById.list = action.payload;
      state.vehicleById.error = null;
    },
    [fetchVehicles.byId.rejected]: (state, action) => {
      state.vehicleById.loading = false;
      state.vehicleById.list = [];
      state.vehicleById.error = action.error.message;
    },
  },
});

export default vehiclesSlice.reducer;
