import { createSlice } from "@reduxjs/toolkit";
import { fetchPlaces } from "../../actions/places/places.action";

const initialState = {
  placesSearch: {
    list: [],
    loading: false,
    error: null,
  },
  placesLocation: {
    list: [],
    loading: false,
    error: null,
  },
  placesFromLatLng: {
    list: [],
    loading: false,
    error: null,
  },
};
//
const placesSlice = createSlice({
  name: "places",
  initialState,
  extraReducers: {
    // placesSearch
    [fetchPlaces.search.pending]: (state) => {
      state.placesSearch.loading = true;
    },
    [fetchPlaces.search.fulfilled]: (state, action) => {
      state.placesSearch.loading = false;
      state.placesSearch.list = action.payload;
      state.placesSearch.error = null;
    },
    [fetchPlaces.search.rejected]: (state, action) => {
      state.placesSearch.loading = false;
      state.placesSearch.list = [];
      state.placesSearch.error = action.error.message;
    },
    //places Location
    [fetchPlaces.location.pending]: (state) => {
      state.placesLocation.loading = true;
    },
    [fetchPlaces.location.fulfilled]: (state, action) => {
      state.placesLocation.loading = false;
      state.placesLocation.list = action.payload;
      state.placesLocation.error = null;
    },
    [fetchPlaces.location.rejected]: (state, action) => {
      state.placesLocation.loading = false;
      state.placesLocation.list = [];
      state.placesLocation.error = action.error.message;
    },
    //places fromLatLng location
    [fetchPlaces.fromLatLng.pending]: (state) => {
      state.placesLocation.loading = true;
    },
    [fetchPlaces.fromLatLng.fulfilled]: (state, action) => {
      state.placesFromLatLng.loading = false;
      state.placesFromLatLng.list = action.payload;
      state.placesFromLatLng.error = null;
    },
    [fetchPlaces.fromLatLng.rejected]: (state, action) => {
      state.placesFromLatLng.loading = false;
      state.placesFromLatLng.list = [];
      state.placesFromLatLng.error = action.error.message;
    },
  },
});

export default placesSlice.reducer;
