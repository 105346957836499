/*!
Data Created On: Wednesday, Jan 4, 2023 [5:33 AM]
=========================================================
* YesRide - v1.0.0
=========================================================

* Copyright 2022
* Licensed under MIT (https://github.com/YesRide/yesride-admin-dashboard/blob/master/LICENSE.md)
* Author:  Abraham Mitiku -> abrilojson@gmail.com

=========================================================
*
*/

/**
 *  Change accordingly
 */

// for production/live server configurations
// use this for production before running > npm run build
const proConfig = {
  API_BASE_URL: `http://46.101.97.3:3000/api/`,
  BASE_URL: `http://46.101.97.3:3000/`,
  IMAGE_URL: `LIVE_IMAGE_URL`,
  GOOGLE_MAP_API_KEY: `AIzaSyCiIOGWK5kuSoNkjMjmERu-mf6Ah834L5w`,
};

// for local/development configurations
// use this for localhost / development
const devConfig = {
  API_BASE_URL: `http://localhost:4000/api/`,
  BASE_URL: "http://localhost:4000",
  IMAGE_URL: "http://localhost:4000/images/",
  GOOGLE_MAP_API_KEY: `AIzaSyCiIOGWK5kuSoNkjMjmERu-mf6Ah834L5w`,
};

//export default devConfig;
export default proConfig;
