import { createAsyncThunk } from "@reduxjs/toolkit";
import { readVehicle } from "../../../api/vehicle.api";

export const fetchVehicles = {
  all: createAsyncThunk("GET_ALL_VEHICLES", async () => {
    let payload = await readVehicle
      .all()
      .then((res) => {
        let { data } = res;
        let response = data.data && data.data;
        return response;
      })
      .catch((err) => {
        return err;
      });
    return payload;
  }),
  byId: createAsyncThunk("GET_VEHICLE_BY_ID", async (data) => {
    let { id } = data;
    let payload = await readVehicle
      .byId(id)
      .then((res) => {
        let { data } = res;
        let response = data.data && data.data;
        return response;
      })
      .catch((err) => {
        return err;
      });
    return payload;
  }),
};
