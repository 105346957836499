// -----------------------------------------------------------------
import instance from "./index";

// CREATE
export const createTrip = {
  /**
   *  pickup_place = {
   *   place_name: value,
   *   location: {lat: value, long: value}
   *  }
   * //---------------
   *
   * destination_place = {
   *   place_name: value,
   *   location: {lat: value, long: value}
   *  }
   *
   *
   */
  new: async (
    vehicle_type_id,
    passenger_id,
    payment_method_id,
    pickup_place,
    destination_place
  ) =>
    await instance.post("trips", {
      vehicle_type_id,
      passenger_id,
      payment_method_id,
      pickup_place,
      destination_place,
    }),

  start: async (id, driver_id) =>
    await instance.post(`trips/${id}/start`, {
      driver_id,
    }),
  /**
   *  payment = {amount: value}
   *
   */
  end: async (id, driver_id, km_covered, time_taken, payment) =>
    await instance.post(`trips/${id}/end`, {
      driver_id,
      km_covered,
      time_taken,
      payment,
    }),
  cancel: async (id, cancellation_reason_id) =>
    await instance.post(`trips/${id}/cancel`, {
      cancellation_reason_id,
    }),
  arrived: async (id, driver_id) =>
    await instance.post(`trips/${id}/arrived`, {
      driver_id,
    }),
  manual: async (
    vehicle_type_id,
    passenger_id,
    payment_method_id,
    pickup_place,
    destination_place
  ) =>
    await instance.post("trips/manual", {
      vehicle_type_id,
      passenger_id,
      payment_method_id,
      pickup_place,
      destination_place,
    }),
  request: {
    accept: async (id, driver_id) =>
      await instance.post(`trip_requests/${id}/accept`, {
        driver_id,
      }),
    reject: async (id, driver_id) =>
      await instance.post(`trip_requests/${id}/reject`, {
        driver_id,
      }),
  },
};

// READ
export const readTrip = {
  all: async (status, page = 1, per_page = 10) => {
    let url =
      status === "All"
        ? `trips?page=${page}&per_page=${per_page}`
        : `trips?status=${status}&page=${page}&per_page=${per_page}`;
    //   console.log(url);
    return await instance.get(url);
  },
  search: async (phone_number) => {
    return await instance.post(`trips/search`, { phone_number: phone_number });
  },
  byId: async (id) => await instance.get(`trips/${id}`),
  request: {
    getById: async (id) => await instance.post(`trip_requests/${id}/requests`),
  },
};
