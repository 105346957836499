/*!
Data Created On: Wednesday, Jan 4, 2023 [5:33 AM]
=========================================================
* YesRide - v1.0.0
=========================================================

* Copyright 2022
* Licensed under MIT (https://github.com/YesRide/yesride-admin-dashboard/blob/master/LICENSE.md)
* Author:  Abraham Mitiku -> abrilojson@gmail.com

=========================================================
*
*/

/***
 *  This is main api configuration for this app
 *  Here you can intercept every request and responses to control and manage user data flow
 */
import axios from "axios";
import config from "../config/config";

import { getLocalStorage } from "../utils/Storage";

//axios.defaults.port = 8080;

// creating instance
const instance = axios.create({
  baseURL: config.API_BASE_URL,
});

// intercepting every requests
instance.interceptors.request.use(
  async (config) => {
    // retreiving token & Attach to every header
    const token = getLocalStorage("token");
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.ContentType = "application/json";
    // config.headers.Accept = "application/json";
    return config;
  },
  async (err) => {
    return Promise.reject(err);
  }
);

// Intercepting every response
instance.interceptors.response.use(
  async (res) => {
    return res;
  },
  async (err) => {
    let originalConfig = err.config;

    if (err.response !== null) {
      // for forbidden user access and to renew token
      if (err.response.status === 401) {
        /**
         *  USE THIS FOR RENEWING TOKEN
         */
        /**
        *  let payload = await instance
          .post(`renew_token_url`)
          .then((res) => {
            let { data } = res;
            return data.access;
          })
          .catch((err) => {
            return Promise.reject(err);
          });
        //console.log(payload);
        axios.defaults.headers.common["Authorization"] = `Bearer ${payload}`;
        setLocalStorage("site", payload);
        */
        return instance(originalConfig);
      } else return Promise.reject(err);
    } else return Promise.reject(err);
    // if unAuthorized error is displayed renewtoken
  }
);

export default instance;
