// -----------------------------------------------------------------
import instance from "./index";
import { format } from "date-fns";
// GET
export const readAnalytics = {
  all: async (start_date, end_date) => {
    // date
    let today = new Date();
    today.setHours(0, 0, 0, 0);

    //6 months ago .. first Date
    let previousFirstDate = new Date();
    previousFirstDate.setHours(0, 0, 0, 0);
    previousFirstDate.setDate(0);
    previousFirstDate.setDate(-160);

    // console.log(format(previousFirstDate, "yyyy/MM/dd"));
    //
    let startDate;
    let endDate;
    //
    if (start_date === end_date || !start_date || !end_date) {
      startDate = format(previousFirstDate, "yyyy/MM/dd");
      endDate = format(today, "yyyy/MM/dd");
    }
    //
    //  console.log(startDate, endDate);
    let url =
      start_date && end_date
        ? `analytics?start_date=${startDate}&end_date=${endDate}`
        : `analytics`;
    return await instance.get(url);
  },
  drivers: async () => await instance.get(`analytics/drivers`),
  earningByRange: async (start_date, end_date) =>
    await instance.get(
      `analytics?start_date=${start_date}&end_date=${end_date}`
    ),
  earning: async (year) => await instance.get(`analytics/earnings/${year}`),
};
