import { createSlice } from "@reduxjs/toolkit";
import { fetchDrivers } from "../../actions/driver/driver.action";

const initialState = {
  allDrivers: {
    list: [],
    loading: false,
    error: null,
  },
  driverById: {
    list: [],
    loading: false,
    error: null,
  },
};
//
const driversSlice = createSlice({
  name: "drivers",
  initialState,
  extraReducers: {
    // allDrivers
    [fetchDrivers.all.pending]: (state) => {
      state.allDrivers.loading = true;
    },
    [fetchDrivers.all.fulfilled]: (state, action) => {
      state.allDrivers.loading = false;
      state.allDrivers.list = action.payload;
      state.allDrivers.error = null;
    },
    [fetchDrivers.all.rejected]: (state, action) => {
      state.allDrivers.loading = false;
      state.allDrivers.list = [];
      state.allDrivers.error = action.error.message;
    },
    //driverById
    [fetchDrivers.byId.pending]: (state) => {
      state.driverById.loading = true;
    },
    [fetchDrivers.byId.fulfilled]: (state, action) => {
      state.driverById.loading = false;
      state.driverById.list = action.payload;
      state.driverById.error = null;
    },
    [fetchDrivers.byId.rejected]: (state, action) => {
      state.driverById.loading = false;
      state.driverById.list = [];
      state.driverById.error = action.error.message;
    },
  },
});

export default driversSlice.reducer;
