import { createAsyncThunk } from "@reduxjs/toolkit";
import { readTrip } from "../../../api/trip.api";

export const fetchTrips = {
  all: createAsyncThunk("GET_ALL_TRIPS", async (data, thunkAPI) => {
    let { status, page, per_page } = data;
    // console.log(data);
    let payload = await readTrip
      .all(status, page, per_page)
      .then((res) => {
        let { data } = res;
        let response = data.data && data.data;
        //   console.log(response);
        let { items, total } = response;
        return {
          list: items,
          total: total,
        };
      })
      .catch((err) => {
        return err;
      });
    return payload;
  }),
  search: createAsyncThunk(
    "GET_ALL_TRIPS_BY_SEARCH",
    async (data, thunkAPI) => {
      let { phone_number } = data;
      // console.log(data);
      let payload = await readTrip
        .search(phone_number)
        .then((res) => {
          let { data } = res;
          let response = data.data && data.data;
          //   console.log(response);
          let { items, total } = response;
          return {
            list: items,
            total: total,
          };
        })
        .catch((err) => {
          return err;
        });
      return payload;
    }
  ),
  byId: createAsyncThunk("GET_TRIPS_BY_ID", async (data) => {
    let { id } = data;
    let payload = await readTrip
      .byId(id)
      .then((res) => {
        let { data } = res;
        let response = data.data && data.data;
        //  console.log(response);
        return response;
      })
      .catch((err) => {
        return err;
      });
    return payload;
  }),
  request: createAsyncThunk(
    "GET_TRIPS_REQUEST_BY_ID",
    async (data, thunkAPI) => {
      let { id } = data;
      let payload = await readTrip.request
        .getById(id)
        .then((res) => {
          let { data } = res;
          let response = data.data && data.data;
          return response;
        })
        .catch((err) => {
          return err;
        });
      return payload;
    }
  ),
};
