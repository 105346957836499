import { createSlice } from "@reduxjs/toolkit";
import { fetchAnalytics } from "../../actions/analytics/analytics.action";

const initialState = {
  allAnalytics: {
    list: [],
    loading: false,
    error: null,
  },
  driversAnalytics: {
    list: [],
    loading: false,
    error: null,
  },
  earningByRangeAnalytics: {
    list: [],
    loading: false,
    error: null,
  },
  earningAnalytics: {
    list: [],
    loading: false,
    error: null,
  },
};

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  extraReducers: {
    // allAnalytics
    [fetchAnalytics.all.pending]: (state) => {
      state.allAnalytics.loading = true;
    },
    [fetchAnalytics.all.fulfilled]: (state, action) => {
      state.allAnalytics.loading = false;
      state.allAnalytics.list = action.payload ? action.payload : [];
      state.allAnalytics.error = null;
    },
    [fetchAnalytics.all.rejected]: (state, action) => {
      state.allAnalytics.loading = false;
      state.allAnalytics.list = [];
      state.allAnalytics.error = action.error.message;
    },
    //driversAnalytics
    [fetchAnalytics.drivers.pending]: (state) => {
      state.driversAnalytics.loading = true;
    },
    [fetchAnalytics.drivers.fulfilled]: (state, action) => {
      state.driversAnalytics.loading = false;
      state.driversAnalytics.list = action.payload ? action.payload : [];
      state.driversAnalytics.error = null;
    },
    [fetchAnalytics.drivers.rejected]: (state, action) => {
      state.driversAnalytics.loading = false;
      state.driversAnalytics.list = [];
      state.driversAnalytics.error = action.error.message;
    },

    // earningAnalytics by range
    [fetchAnalytics.earningByRange.pending]: (state) => {
      state.earningByRangeAnalytics.loading = true;
    },
    [fetchAnalytics.earningByRange.fulfilled]: (state, action) => {
      state.earningByRangeAnalytics.loading = false;
      state.earningByRangeAnalytics.list = action.payload ? action.payload : [];
      state.earningByRangeAnalytics.error = null;
    },
    [fetchAnalytics.earningByRange.rejected]: (state, action) => {
      state.earningByRangeAnalytics.loading = false;
      state.earningByRangeAnalytics.list = [];
      state.earningByRangeAnalytics.error = action.error.message;
    },

    // earningAnalytics
    [fetchAnalytics.earning.pending]: (state) => {
      state.earningAnalytics.loading = true;
    },
    [fetchAnalytics.earning.fulfilled]: (state, action) => {
      state.earningAnalytics.loading = false;
      state.earningAnalytics.list = action.payload ? action.payload : [];
      state.earningAnalytics.error = null;
    },
    [fetchAnalytics.earning.rejected]: (state, action) => {
      state.earningAnalytics.loading = false;
      state.earningAnalytics.list = [];
      state.earningAnalytics.error = action.error.message;
    },
  },
});

export default analyticsSlice.reducer;
