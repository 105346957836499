import { configureStore } from "@reduxjs/toolkit";
//import { applyMiddleware } from "redux";
//import thunk from "redux-thunk";

// analytics
import analyticsSlice from "../reducers/analytics/analytics.slice";
// drivers
import driversSlice from "../reducers/driver/driver.slice";
// vehicles
import vehiclesSlice from "../reducers/vehicle/vehicle.slice";
// trips
import tripsSlice from "../reducers/trip/trip.slice";
// reviews
import reviewsSlice from "../reducers/review/review.slice";
// places
import placesSlice from "../reducers/places/places.slice";

const store = configureStore({
  reducer: {
    // analytics
    analytics: analyticsSlice,

    // drivers
    drivers: driversSlice,

    // vehicles
    vehicles: vehiclesSlice,

    // trips
    trips: tripsSlice,

    // reviews
    reviews: reviewsSlice,

    // places
    places: placesSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

/**
 *  fetch data's as soon as app loads
 */
//store.dispatch();

export default store;
