// -----------------------------------------------------------------
import instance from "./index";

// CREATE
export const createReview = {
  new: async (tripId, comment, rating) =>
    await instance.post("trip_reviews", {
      tripId,
      comment,
      rating,
    }),
};

// GET
export const readReview = {
  all: async () => await instance.get(`trip_reviews`),
};
