import { createAsyncThunk } from "@reduxjs/toolkit";
import { readReview } from "../../../api/review.api";

export const fetchReviews = {
  all: createAsyncThunk("GET_ALL_REVIEWS", async () => {
    let payload = await readReview
      .all()
      .then((res) => {
        let { data } = res;
        let response = data.data && data.data;
        return response;
      })
      .catch((err) => {
        return err;
      });
    return payload;
  }),
};
