import { createSlice } from "@reduxjs/toolkit";
import { fetchReviews } from "../../actions/review/review.action";

const initialState = {
  allReviews: {
    list: [],
    loading: false,
    error: null,
  },
};
//
const reviewsSlice = createSlice({
  name: "reviews",
  initialState,
  extraReducers: {
    // allReviews
    [fetchReviews.all.pending]: (state) => {
      state.allReviews.loading = true;
    },
    [fetchReviews.all.fulfilled]: (state, action) => {
      state.allReviews.loading = false;
      state.allReviews.list = action.payload;
      state.allReviews.error = null;
    },
    [fetchReviews.all.rejected]: (state, action) => {
      state.allReviews.loading = false;
      state.allReviews.list = [];
      state.allReviews.error = action.error.message;
    },
  },
});

export default reviewsSlice.reducer;
