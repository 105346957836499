import { createSlice } from "@reduxjs/toolkit";
import { fetchTrips } from "../../actions/trip/trip.action";

const initialState = {
  allTrips: {
    list: [],
    totalCount: 0,
    loading: false,
    error: null,
  },
  tripById: {
    list: [],
    loading: false,
    error: null,
  },
  tripsRequestById: {
    list: [],
    loading: false,
    error: null,
  },
};
//
const tripsSlice = createSlice({
  name: "trips",
  initialState,
  extraReducers: {
    // allTrips
    [fetchTrips.all.pending]: (state) => {
      state.allTrips.loading = true;
    },
    [fetchTrips.all.fulfilled]: (state, action) => {
      state.allTrips.loading = false;
      state.allTrips.list =
        action.payload && action.payload.list ? action.payload.list : [];
      state.allTrips.totalCount =
        action.payload && action.payload.total ? action.payload.total : 0;
      state.allTrips.error = null;
    },
    [fetchTrips.all.rejected]: (state, action) => {
      state.allTrips.loading = false;
      state.allTrips.list = [];
      state.allTrips.totalCount = 0;
      state.allTrips.error = action.error.message;
    },
    // search trips
    [fetchTrips.search.pending]: (state) => {
      state.allTrips.loading = true;
    },
    [fetchTrips.search.fulfilled]: (state, action) => {
      state.allTrips.loading = false;
      state.allTrips.list = action.payload ? action.payload : [];
      state.allTrips.error = null;
    },
    [fetchTrips.search.rejected]: (state, action) => {
      state.allTrips.loading = false;
      state.allTrips.list = [];
      state.allTrips.error = action.error.message;
    },
    //tripById
    [fetchTrips.byId.pending]: (state) => {
      state.tripById.loading = true;
    },
    [fetchTrips.byId.fulfilled]: (state, action) => {
      state.tripById.loading = false;
      state.tripById.list = action.payload;
      state.tripById.error = null;
    },
    [fetchTrips.byId.rejected]: (state, action) => {
      state.tripById.loading = false;
      state.tripById.list = [];
      state.tripById.error = action.error.message;
    },
    //trips request ById
    [fetchTrips.request.pending]: (state) => {
      state.tripById.loading = true;
    },
    [fetchTrips.request.fulfilled]: (state, action) => {
      state.tripsRequestById.loading = false;
      state.tripsRequestById.list = action.payload;
      state.tripsRequestById.error = null;
    },
    [fetchTrips.request.rejected]: (state, action) => {
      state.tripsRequestById.loading = false;
      state.tripsRequestById.list = [];
      state.tripsRequestById.error = action.error.message;
    },
  },
});

export default tripsSlice.reducer;
