import { createAsyncThunk } from "@reduxjs/toolkit";
import { readAnalytics } from "../../../api/analytics.api";

export const fetchAnalytics = {
  all: createAsyncThunk("GET_ALL", async (data, thunkAPI) => {
    //
    let { start_date, end_date } = data;
    let payload = await readAnalytics
      .all(start_date, end_date)
      .then((res) => {
        let { data } = res;
        let response = data.data && data.data;
        return response;
      })
      .catch((err) => {
        return err;
      });
    return payload;
  }),
  drivers: createAsyncThunk("GET_DRIVERS", async () => {
    let payload = await readAnalytics
      .drivers()
      .then((res) => {
        let { data } = res;
        let response = data.data && data.data;
        return response;
      })
      .catch((err) => {
        return err;
      });
    return payload;
  }),

  earningByRange: createAsyncThunk(
    "GET_EARNING_BY_RANGE",
    async (data, thunkAPI) => {
      //
      let { start_date, end_date } = data;

      let payload = await readAnalytics
        .earningByRange(start_date, end_date)
        .then((res) => {
          let { data } = res;
          let response = data.data && data.data;

          return response;
        })
        .catch((err) => {
          return err;
        });
      return payload;
    }
  ),
  earning: createAsyncThunk("GET_EARNING", async (data) => {
    let { year } = data;

    let payload = await readAnalytics
      .earning(year)
      .then((res) => {
        let { data } = res;
        let response = data.data && data.data;
        return response;
      })
      .catch((err) => {
        return err;
      });
    return payload;
  }),
};
