// -----------------------------------------------------------------
import instance from "./index";

// CREATE
export const createDriver = {
  add: async (formData) => await instance.post(`drivers`, formData),
};

// GET
export const readDriver = {
  all: async () => await instance.get(`drivers`),
  byId: async (id) => await instance.get(`drivers/${id}`),
  vehicle: async (id) => await instance.get(`drivers/${id}/vehicles`),
  trip: async (id) => await instance.get(`drivers/${id}/trip_history`),
  nearBy: async (location, vehicle_type_id) =>
    await instance.get(
      `drivers/nearby?location=${location}&vehicle_type_id=${vehicle_type_id}`
    ),
};

// UPDATE
export const updateDriver = {
  location: async (id, heading, latitude, longitude) =>
    await instance.post(`drivers/${id}/updateLocation`, {
      heading,
      latitude,
      longitude,
    }),
  uploadDocument: async (id, formData) =>
    await instance.post(`drivers/${id}/documents`, formData),
  status: async (id, account_status, active_status) =>
    await instance.patch(`drivers/${id}/status`, {
      account_status,
      active_status,
    }),
  driver: async (id, sex, dob, country, state, city, device_token) =>
    await instance.patch(`drivers/${id}`, {
      sex,
      dob,
      country,
      state,
      city,
      device_token,
    }),
};
