/**
 *  Importing View Components [Pages] , Layouts && Routes
 */

import React from "react";
import loadable from "@loadable/component";

/**
 * Public | Auth Views
 * !Public view pages should be imported here and add with public view categories
 */
// not found pages
const NotFound = loadable(() => import("../components/views/404/NotFound"));
// auth pages
const Login = loadable(() => import("../components/views/auth/Login"));
const ForgotPassword = loadable(() =>
  import("../components/views/auth/ForgotPassword")
);
const ResetPassword = loadable(() =>
  import("../components/views/auth/ResetPassword")
);
const Verify = loadable(() => import("../components/views/auth/Verify"));
const Notify = loadable(() => import("../components/views/auth/Notify"));
//----------------------------------------------------------------------------------------------

/**
 * Private Views
 * Authorized pages [views] should be imported here and add with private view categories
 */
//
const Dashboard = loadable(() =>
  import("../components/views/private/dashboard/Dashboard")
);
const Profile = loadable(() =>
  import("../components/views/private/profile/Profile")
);
const Driver = loadable(() =>
  import("../components/views/private/driver/Driver")
);
const EditDriver = loadable(() =>
  import("../components/views/private/driver/Edit")
);
const NewDriver = loadable(() =>
  import("../components/views/private/driver/New")
);
const Fare = loadable(() => import("../components/views/private/fare/Fare"));
const NewFare = loadable(() => import("../components/views/private/fare/New"));
const Passenger = loadable(() =>
  import("../components/views/private/passenger/Passenger")
);
const Trip = loadable(() => import("../components/views/private/trip/Trip"));
const TripDetail = loadable(() =>
  import("../components/views/private/trip/TripDetail")
);
const User = loadable(() => import("../components/views/private/user/User"));
const Vehicle = loadable(() =>
  import("../components/views/private/vehicle/Vehicle")
);
const NewVehicle = loadable(() =>
  import("../components/views/private/vehicle/New")
);
const EditVehicle = loadable(() =>
  import("../components/views/private/vehicle/Edit")
);
const Setting = loadable(() =>
  import("../components/views/private/setting/Setting")
);

/**
 *  Layouts [Private, Public]
 *  Contains - [Outlet, Navigations ... etc]
 */

const PrivateLayout = loadable(() =>
  import("../components/layouts/PrivateLayout")
);

const PublicLayout = loadable(() =>
  import("../components/layouts/PublicLayout")
);

/**
 *  Authorized Routes [PrivateRoute]
 *  Checks whether personel is authorized or not ... or will redirect to login page ...
 *  You can change authorization methods for pages [views] here from [PrivateRoute]
 */
const PrivateRoute = loadable(() => import("./PrivateRoute"));
const PublicRoute = loadable(() => import("./PublicRoute"));

const routes = [
  /**
   *  Public Views
   *  Directories:
   *  Views -> home,
   */
  {
    path: "/",
    element: <PublicLayout />,
    children: [
      {
        index: true,
        element: (
          <PublicRoute>
            <Login />
          </PublicRoute>
        ),
      },
      {
        path: "/auth",
        element: (
          <PublicRoute>
            <Login />
          </PublicRoute>
        ),
      },
      {
        path: "/auth/login",
        element: (
          <PublicRoute>
            <Login />
          </PublicRoute>
        ),
      },
      {
        path: "/auth/forgot-password",
        element: (
          <PublicRoute>
            <ForgotPassword />
          </PublicRoute>
        ),
      },
      {
        path: "/auth/reset-password",
        element: (
          <PublicRoute>
            <ResetPassword />
          </PublicRoute>
        ),
      },
      {
        path: "/auth/verify-email/:id",
        element: (
          <PublicRoute>
            <Verify />
          </PublicRoute>
        ),
      },
      {
        path: "/auth/notification",
        element: (
          <PublicRoute>
            <Notify />
          </PublicRoute>
        ),
      },
    ],
  },

  /**
   *
   */
  {
    path: "*",
    element: <NotFound />,
  },
  /**
   *  Private Views
   *  Directories:
   *  Views -> Private -> [Dashboard, Drivers, Vehicles, Trips, Passengers, ... etc]
   */
  {
    path: "/admin",
    element: <PrivateLayout />,
    children: [
      {
        index: true,
        element: (
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        ),
      },
      {
        path: "/admin/dashboard",
        element: (
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        ),
      },
      {
        path: "/admin/driver",
        element: (
          <PrivateRoute>
            <Driver />
          </PrivateRoute>
        ),
      },
      {
        path: "/admin/driver/new",
        element: (
          <PrivateRoute>
            <NewDriver />
          </PrivateRoute>
        ),
      },
      {
        path: "/admin/driver/update/:id",
        element: (
          <PrivateRoute>
            <EditDriver />
          </PrivateRoute>
        ),
      },
      {
        path: "/admin/fare",
        element: (
          <PrivateRoute>
            <Fare />
          </PrivateRoute>
        ),
      },
      {
        path: "/admin/fare/new",
        element: (
          <PrivateRoute>
            <NewFare />
          </PrivateRoute>
        ),
      },
      {
        path: "/admin/passengers",
        element: (
          <PrivateRoute>
            <Passenger />
          </PrivateRoute>
        ),
      },
      {
        path: "/admin/trip",
        element: (
          <PrivateRoute>
            <Trip />
          </PrivateRoute>
        ),
      },
      {
        path: "/admin/trip/:id",
        element: (
          <PrivateRoute>
            <TripDetail />
          </PrivateRoute>
        ),
      },
      {
        path: "/admin/user",
        element: (
          <PrivateRoute>
            <User />
          </PrivateRoute>
        ),
      },
      {
        path: "/admin/user/list",
        element: (
          <PrivateRoute>
            <User />
          </PrivateRoute>
        ),
      },
      {
        path: "/admin/vehicle",
        element: (
          <PrivateRoute>
            <Vehicle />
          </PrivateRoute>
        ),
      },
      {
        path: "/admin/vehicle/new",
        element: (
          <PrivateRoute>
            <NewVehicle />
          </PrivateRoute>
        ),
      },
      {
        path: "/admin/vehicle/update/:id",
        element: (
          <PrivateRoute>
            <EditVehicle />
          </PrivateRoute>
        ),
      },
      {
        path: "/admin/profile",
        element: (
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        ),
      },
      {
        path: "/admin/settings",
        element: (
          <PrivateRoute>
            <Setting />
          </PrivateRoute>
        ),
      },
    ],
  },
];
export default routes;
