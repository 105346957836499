import { createAsyncThunk } from "@reduxjs/toolkit";
import { readDriver } from "../../../api/driver.api";

export const fetchDrivers = {
  all: createAsyncThunk("GET_ALL_DRIVERS", async () => {
    let payload = await readDriver
      .all()
      .then((res) => {
        let { data } = res;
        let response = data.data && data.data;
        return response;
      })
      .catch((err) => {
        return err;
      });
    return payload;
  }),
  byId: createAsyncThunk("GET_DRIVERS_BY_ID", async (data) => {
    let { id } = data;
    let payload = await readDriver
      .byId(id)
      .then((res) => {
        let { data } = res;
        let response = data.data && data.data;
        return response;
      })
      .catch((err) => {
        return err;
      });
    return payload;
  }),
};
