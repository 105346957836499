/*!
Data Created On: Wednesday, Jan 4, 2023 [5:33 AM]
=========================================================
* YesRide - v1.0.0
=========================================================

* Copyright 2022
* Licensed under MIT (https://github.com/YesRide/yesride-admin-dashboard/blob/master/LICENSE.md)
* Author:  Abraham Mitiku -> abrilojson@gmail.com

=========================================================
*
*/

// setting localStorage data's
export const setLocalStorage = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};
// getting localStorage data's
export const getLocalStorage = (key) => {
  const value = window.localStorage.getItem(key);
  if (value) return JSON.parse(value);
};
//removing localStorage data's
export const removeLocalStorage = (key) => {
  window.localStorage.removeItem(key);
};
